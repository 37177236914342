.mainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	margin: 0;
	padding: 0;
	background-color: #f0f0f0;
}

.form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	align-items: flex-start;
	justify-content: center;
}

.user-box-input {
	height: 48px;
	width: 400px;
	font-size: large;
	border-radius: 8px;
	border: 1px solid grey;
	padding-left: 8px;
}

.user-box-input:focus {
	outline: none;
	box-shadow: 0 0 2px 1px rgba(0, 140, 255, 0.5);
}

.error {
	color: red;
	font-size: 0.8rem;
	margin-top: 0.2rem;
	align-self: flex-start;
	display: block;
}

.btn-primary {
	background-color: #0095ff;
	border: none;
	border-radius: 4px;
	color: #fff;
	padding: 0.5rem 2rem;
	font-size: 1rem;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.btn-primary:hover {
	background-color: #0077cc;
}

.btn-secondary {
	border: none;
	border-radius: 4px;
	padding: 0.5rem 2rem;
	font-size: 1rem;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.user-info-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px;
	padding: 0;
}

.change-password-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px;
	padding: 0;
}

.create-user-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px auto;
}

label {
	display: block;
	margin: 5px;
}

.form-row {
	display: flex;
	margin-bottom: 1rem;
}

.form-field-container {
	margin-left: 10px;
	margin-right: 10px;
}

.filter-box-input {
	height: 36px;
	font-size: large;
	border-radius: 8px;
	border: 1px solid grey;
	padding-left: 8px;
	padding-right: 8px;
}

.nav-item {
	margin-right: 1px;
}

.nav-item:hover {
	background-color: lightgray;
}

.nav-item .active-link {
	background-color: skyblue;
}

.page-error {
	display: flex;
    color: red;
    padding: 10px;
    margin: 10px;
	font-weight: bold;
}

.page-success {
	display: flex;
    color: darkgreen;
    padding: 10px;
    margin: 10px;
	font-weight: bold;
}

.close {
    background: none;
    border: none;
}

.inline-label {
	display: inline-block;
	margin-right: 10px; 
}

.button-label {
	display: inline-block;
	padding: 4px 10px; 
	line-height: 1.5; 
	border: 1px solid #ccc;
	border-radius: 4px;
	background-color: #f8f9fa;
	cursor: pointer;
	text-align: center;
	color: #333;
	text-decoration: none;
	font-size: 14px; 
}

.label-container {
	display: flex;
	align-items: center;
	justify-content: space-between; 
}
.reason-label {
    display: flex;    
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left; 
    white-space: normal; 
    word-wrap: break-word; 
    word-break: break-word; 
    border: 1px solid #6c757d;
    border-radius: 0.25rem;
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    min-height: 2.25rem; 
    width: 25ch; 
}
