html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
		Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

* {
	box-sizing: border-box;
}

main {
	padding: 5rem 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

code {
	background: #fafafa;
	border-radius: 5px;
	padding: 0.75rem;
	font-family: Menlo, Monaco, Lucida Console, Courier New, monospace;
}

input[type="button"] {
	border: none;
	background: cornflowerblue;
	color: white;
	padding: 12px 24px;
	margin: 8px;
	font-size: 24px;
	border-radius: 8px;
	cursor: pointer;
}

a:hover {
	cursor: pointer;
}